@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Roboto+Slab&display=swap');

:root {
    --default-layout-orientation: column;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto Slab', serif;

    line-height: 1.6;
    font-size: 18px;

    --space-cadet: #1b264fff;
    --dark-cornflower-blue: #274690ff;
    --blue-yonder: #576ca8ff;
    --jet: #302b27ff;
    --cultured: #f5f3f5ff;

    --primary-color: var(--space-cadet);
    --secondary-color: var(--dark-cornflower-blue);
    --accent-color: var(--jet);
    --text-color: var(--cultured);
    --divider-color: var(--blue-yonder);
}

@media (min-width: 1024px) {
    :root {
        --default-layout-orientation: row;
    }

}

* {
    box-sizing: border-box;
}

a {
    color: #0070f3;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    display: block;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Quicksand', sans-serif;
}
